<template>
  <CRow>
    <CModal :show.sync="op_confirm.show" :centered="true" :title="$t(op_confirm.title) + ` [ID: ${op_confirm.value}]`" size="lg">
      {{ $t(op_confirm.message) }}
      <template #footer>
        <CButton type="button" class="mr-2" :color="op_confirm.color_yes || 'primary'" @click="onModalConfirmed(op_confirm.response_yes || true)">{{ $t(op_confirm.yes || 'Yes') }}</CButton>
        <CButton v-if="op_confirm.no" type="button" :color="op_confirm.color_no || 'default'" @click="onModalConfirmed(op_confirm.response_no || false)">{{ $t(op_confirm.no || 'No') }}</CButton>
        <CButton v-if="op_confirm.cancel !== false" type="button" :color="op_confirm.color_cancel || 'secondary'" @click="onModalConfirmed(op_confirm.response_cancel)">{{ $t('Cancel') }}</CButton>
      </template>
    </CModal>

    <CModal :show.sync="showModalCRUD" :centered="true" :title="$t(modal_config.title)" size="lg">
      <template v-if="showModalCRUD">
        <ModalCRUD :options="modal_config" @modalUpdated="loadUserPreferences" @parentHide="showModalCRUD = false" />
      </template>
      <template #footer-wrapper><span></span></template>
    </CModal>

    <CCol col="12">
      <AMultiSelectMaster name="template" :value.sync="template.id" :options="user_preferences" :config="{ delete: true, save: true }" @input="onTemplateChanged" @addElement="onAddTemplate" @saveElement="onSaveTemplate" @removeElement="onRemoveTemplate" />
    </CCol>

    <CCol col="12" xl="12" class="mb-3">
      <label class="">{{ $t('Available views') }}</label>
      <draggable v-model="available_columns" group="people" @start="drag = true" @end="redrawData" class="draggable-box">
        <CButton class="mr-2" color="secondary" v-for="element in available_columns" :key="element.key">{{ $t(element.label || _humanize(element.key)) }}</CButton>
      </draggable>
    </CCol>
    <CCol col="12" xl="12" class="mb-3">
      <label class="">{{ $t('Selected views') }}</label>
      <draggable v-model="selected_columns" group="people" @start="drag = true" @end="redrawData" class="draggable-box">
        <CButton class="mr-2" color="info" v-for="element in selected_columns" :key="element.key">{{ $t(element.label || _humanize(element.key)) }}</CButton>
      </draggable>
    </CCol>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ACRUDTable
          ref="crud_table"
          :fields="[
            //'key',
            ...dynamic_fields
          ]"
          :config="{
            name: 'invoice',
            url: 'admin/reports/invoicing_cube',
            route: '/admin/reports/invoicing_cube',
            display_name: 'Cubo de valores de facturación',
            params: { _lists: 'months,buildings,building_types,property_units,invoice_detail_classes,invoice_detail_types,imputations' }, //,preferences:invoicing_cube' },
            filters: [
              { key: 'month_ids', default: 'response.data.parameters.month_ids', multiple: true, forced: true, list: 'months', callback: value => (this.parameters.month_ids = value), buttonCallback: this.onGetData, config: { select_all: true } },
              { key: 'building_code', default: 'response.data.parameters.building_ids', multiple: true, label: 'Building', config: { label: 'identifier' }, forced: false, list: 'buildings', callback: value => (this.parameters.building_ids = value) },

              { key: 'imputation_id', default: 'response.data.parameters.imputation_ids', multiple: true, label: 'Concept', forced: false, list: 'imputations', callback: value => (this.parameters.imputation_ids = value), config: { select_all: true } },
              { key: 'invoice_detail_type_id', default: 'response.data.parameters.invoice_detail_type_ids', multiple: true, label: 'Type', forced: false, list: 'invoice_detail_types', callback: value => (this.parameters.invoice_detail_type_ids = value), config: { select_all: true } },
              { key: 'class_id', default: 'response.data.parameters.class_ids', multiple: true, forced: false, list: 'invoice_detail_classes', callback: value => (this.parameters.class_ids = value), config: { select_all: true } }
            ],
            noCreate: true,
            noOptions: 'hide',
            parseItems
          }"
          @preparedHelpers="onPreparedHelpers"
        />
      </transition>
    </CCol>
  </CRow>
</template>

<script>
const EMPTY_TEMPLATE = { id: 0 }

import { get_attribute } from '@/app/_utils/global-utils'
import draggable from 'vuedraggable'
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'InvoicingCube',
  mixins: [crudMixin],
  components: {
    draggable
  },
  data() {
    return {
      drag: false,
      loading: false,
      template: EMPTY_TEMPLATE,
      parameters: {},
      building_id: 0,
      modal_config: {},
      report_config: {},
      showModalCRUD: false,
      original_items: [],
      dynamic_fields: [],
      user_preferences: [],
      available_columns: [{ key: 'building_type' }, { key: 'property_unit' }, { key: 'property_class' }, { key: 'origin_month' }],
      selected_columns: [{ key: 'imputation', label: 'Concept' }, { key: 'invoice_detail_type', label: 'Type' }, { key: 'class' }, { key: 'building' }],
      op_confirm: { show: false, title: 'Remove row', message: 'Are you sure you want to delete this row?', value: false } // TODO: convert Modal Confirm to global
    }
  },
  mounted() {
    this.loadUserPreferences()
  },
  methods: {
    /*setMonths(month_ids) {
      this.month_ids = month_ids
    },
    setBuildings(building_ids) {
      this.building_ids = building_ids
    },*/
    onGetData() {
      // TODO: grab month_ids and building_ids from the input ??
      this.loading = true
      this.$refs.crud_table.getData(false, this.parameters, true)
    },
    parseItems(parent, items) {
      this.loading = false
      /*this.month_ids = this.$refs.crud_table.response.data.month_ids
      this.building_ids = this.$refs.crud_table.response.data.building_ids*/
      this.parameters = this.$refs.crud_table.response.data.parameters
      this.original_items = items

      this.$set(this.$refs.crud_table.options, 'file_name', this.property.name + ' - ' + 'Cubo de valores de facturación') // TODO: check this with multiple + this.building_id ? 'Todos' : this.parsers.readHelper(this.building_id, 'buildings', 'identifier'))

      return this.parseData()
    },
    parseColumns() {
      this.dynamic_fields = []
      for (let column in this.selected_columns) {
        column = this.selected_columns[column]
        this.dynamic_fields.push(column)
      }
      for (const month_id of this.parameters.month_ids.sort()) {
        this.dynamic_fields.push({ key: month_id, label: this.parsers.periodName(month_id), _classes: 'text-right', formatter: 'numeric', default: 0, render: value => this.parsers.numeric(value || 0, 'decimal', 0) })
      }
      if (this.parameters.month_ids.length >= 2) {
        this.dynamic_fields.push({ key: 'difference', formatter: 'numeric', default: 0, render: value => this.parsers.numeric(value || 0, 'decimal', 0), _classes: 'text-right ' /*value => 'text-right ' + (value ? (value > 0 ? 'bg-success' : 'bg-danger') : '')*/ })
        this.dynamic_fields.push({ key: 'variation', _classes: 'text-right', formatter: 'numeric', default: 0, render: value => this.parsers.numeric(value || 0) })
      }
    },
    parseData() {
      this.drag = false
      this.parseColumns()
      this.setReportConfig()

      const new_rows = {}
      for (let item in this.original_items) {
        item = this.original_items[item]
        for (let invoice_detail in item.invoice_details) {
          invoice_detail = item.invoice_details[invoice_detail]
          const grouping_key = this.getGroupingKey(item, invoice_detail)
          new_rows[grouping_key] = { key: grouping_key, ...invoice_detail, building: item.building, [invoice_detail.month_id]: 0, ...(new_rows[grouping_key] || {}) }
          new_rows[grouping_key][invoice_detail.month_id] += invoice_detail.value
        }
      }

      const months = this.parameters.month_ids.length
      const new_items = []
      Object.values(new_rows).forEach(item => {
        const item_data = {
          key: item.key,
          building_type: this.parsers.readHelper(item.building.building_type_id, 'building_types', 'name'),
          property_unit: this.parsers.readHelper(item.building.property_unit_id, 'property_units', 'name'),
          property_class: this.parsers.readHelper(item.building.property_class_id, 'property_class', 'name'),
          origin_month: this.parsers.periodName(item.origin_month_id),
          imputation: this.parsers.readHelper(item.imputation_id, 'imputations', 'name'),
          invoice_detail_type: this.parsers.readHelper(item.invoice_detail_type_id, 'invoice_detail_types', 'name'),
          class: this.parsers.readHelper(item.class_id, 'invoice_detail_classes', 'name'),
          building: get_attribute(item, 'building.identifier')
        }
        let count = 0
        let variation = 0
        let difference = null
        for (const month_id of this.parameters.month_ids.sort().reverse()) {
          count++
          item_data[month_id] = item[month_id] || 0
          if (months >= 2 && count <= 2) {
            if (difference !== null) {
              const _value = Math.abs(item_data[month_id])
              difference = Math.abs(difference) - _value
              variation = item_data[month_id] ? (difference && Math.abs(difference) !== _value ? (difference * 100) / item_data[month_id] : 0) : difference ? 100 : 0
            } else {
              difference = item_data[month_id]
            }
          }
          if (count >= 2) break
        }
        if (months >= 2) {
          item_data.variation = variation ? variation : difference ? (difference > 0 ? 100 : -100) : 0
          item_data.difference = difference
        }
        new_items.push(item_data)
      })

      return new_items
    },
    redrawData() {
      this.$refs.crud_table.drawItems(this.original_items)
    },
    // TODO: faster way ?
    getGroupingKey(item, invoice_detail) {
      let grouping_key = ''
      for (let column in this.selected_columns) {
        column = this.selected_columns[column]
        switch (column.key) {
          case 'building_type':
            grouping_key += item.building.building_type_id + '-'
            break
          case 'property_unit':
            grouping_key += item.building.property_unit_id + '-'
            break
          case 'property_class':
            grouping_key += item.building.property_class_id + '-'
            break
          case 'origin_month':
            grouping_key += invoice_detail.origin_month_id + '-'
            break
          case 'imputation':
            grouping_key += invoice_detail.imputation_id + '-'
            break
          case 'invoice_detail_type':
            grouping_key += invoice_detail.invoice_detail_type_id + '-'
            break
          case 'class':
            grouping_key += invoice_detail.class_id + '-'
            break
          case 'building':
            grouping_key += invoice_detail.building_id + '-'
            break
        }
      }
      return grouping_key
    },
    setReportConfig() {
      this.report_config = {
        parameters: this.parameters,
        columns: {
          selected: this.selected_columns,
          available: this.available_columns
        }
      }
    },

    setPayload() {
      return { ...this.template, type: 'invoicing_cube', entity: 'Reports', preferences: this.report_config }
    },
    onTemplateChanged(template_id) {
      this.template = template_id ? this.user_preferences.find(item => item.id === template_id) : EMPTY_TEMPLATE
      this.parameters = this.template.preferences.parameters
      this.selected_columns = this.template.preferences.columns.selected
      this.available_columns = this.template.preferences.columns.available
      this.onGetData()
    },
    onAddTemplate() {
      //console.log(type)
      this.modal_config = {
        url: `user_preferences`,
        name: 'user_preference',
        type: `user_preferences`, // Pluralize
        field: `template_id`,
        title: 'Invoicing cube - User template',
        modal: true,
        perPage: 5,
        only_form: true,
        payload: this.setPayload(),
        fields: ['name']
      }
      this.showModalCRUD = true
    },
    onSaveTemplate() {
      this.setUserPreferences(this.setPayload(), () => this.loadUserPreferences())
    },
    onRemoveTemplate() {
      this.op_confirm.show = true
      this.op_confirm.value = this.template.id
    },
    onModalConfirmed(response) {
      this.op_confirm.show = false
      if (response) {
        this.removeUserPreferences(this.template.id, () => this.loadUserPreferences())
        this.op_confirm.value = false
        this.template = EMPTY_TEMPLATE
      }
    },

    loadUserPreferences() {
      this.getUserPreferences({ type: 'invoicing_cube' }, response => {
        this.user_preferences = response.data.user_preferences
        if (!this.user_preferences.length) this.template = EMPTY_TEMPLATE
      })
    }
  }
}
</script>

<style lang="scss">
.draggable-box {
  height: 100px;
  border: 1px solid lightgray;
  padding: 5px;
  background-color: #ccc;
}
</style>
